<template>
    <div>
        <div class="screenWidth">
            <currentLocation :sibItem="sibItem"></currentLocation>
            <div class="name">
                <h1>PRODUCTS</h1>
                <div>YIDA-HPMC Cellulose Manufacturer</div>
                <div class="details_box_left_pb">
                    <el-select @change="selectclick" v-model="value" placeholder="请选择">
                        <el-option v-for="item in cate_list_yd" :key="item.id" :label="item.name" :value="item.id">
                        </el-option>
                    </el-select>
                </div>
            </div>

            <div class="Product_list">
                <div class="Product_list_left">
                    <div class="Product_list_left_one">
                        PRODUCT CATEGORY
                    </div>
                    <div class="Product_list_left_one_list">
                        <ul>
                            <li @click="ckfnelei(item.id)" v-for="(item) in cate_list" :key="item.id">
                                <div class="fha"><span
                                        :style="{ 'background': flid == item.id ? '#BA0003' : '' }"></span>
                                </div>
                                <div :class="{ 'text': true, 'activebk': flid == item.id }">{{ item.name }}</div>
                            </li>
                        </ul>
                    </div>
                </div>
                <div class="Product_list_right">
                    <div class="list">
                        <div @click="toproductDetails(item.id)" class="Product_list_box" v-for="(item) in Productlist"
                            :key="item.id">
                            <img :src="item.image" alt="">
                            <div>{{ item.name }}</div>
                        </div>
                    </div>
                    <div class="kong" v-if="Productlist.length < 1">
                        <img src="../assets/image/kong.png" alt="">
                        <div>No Data</div>
                    </div>

                    <div class="yema" v-if="totalPages > 1">
                        <el-pagination @prev-click="Previous" @next-click="NextPage"
                            @current-change="handleCurrentChange" background layout="prev, pager, next"
                            :page-count="totalPages">
                        </el-pagination>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
/**
 * Created by www.xingnuocn.com
 * @title：产品列表
 * @author: 刘义博
 * @version  V1.0 
 * @datecreate: 2024-07-18 09:13
 */
export default {
    data() {
        return {
            sibItem: [
                {
                    title: "Home",
                    path: "/",
                },
                {
                    title: "product",
                    path: "",
                },
            ],
            totalPages: 0,//总页码
            currentPage: 1, // 当前页数，默认为第一页
            cate_list: [],//产品分类
            cate_list_yd: [],
            Productlist: [],//产品列表
            flid: '',//分类id
            value: '',
        }
    },
    mounted() {
        let query = this.$route.query
        console.log(query, '产品列表接收参数');
        this.flid = query.id?query.id:''
        this.value = query.name?query.name:'ALL'
        this.getproductcategories()
    },
    methods: {
        selectclick(e) {
            this.flid = this.value
            this.getProductlist()
        },
        /**
         * 分类点击
         * 刘义博
         * 2024-7-20
         */
        ckfnelei(id) {
            console.log(id, 'id');
            this.flid = id
            this.currentPage = 1
            this.getProductlist()
            window.scrollTo({
                top: 0,
                behavior: 'smooth' // 平滑滚动效果
            })
        },
        /**
         * 获取产品列表
         * 刘义博
         * 2024-7-20
         */
        getProductlist() {
            this.$request({
                url: "/Index/goodsList",
                data: {
                    cate_id: this.flid,
                    page: this.currentPage
                }
            }).then((res) => {
                console.log("产品列表", res);
                this.Productlist = res.data.goods_list
                this.totalPages = Math.ceil(res.data.goods_count / 12);
            });
        },
        /**
         * 获取产品分类
         * 刘义博
         * 2024-7-20
         */
        getproductcategories() {
            this.$request({
                url: "/Index/goodsCateOne",
            }).then((res) => {
                console.log("产品分类", res.data.cate_list);
                this.cate_list = res.data.cate_list
                this.cate_list_yd = res.data.cate_list
                this.cate_list_yd.unshift({ name: 'All', id: '' })
                this.getProductlist(0)
            });
        },
        /**
         * 去产品详情
         * 刘义博
         * 2024-7-20
         */
        toproductDetails(id) {
            this.$router.push({
                path: '/productDetails',
                query: { id: id }
            });
        },
        // 上一页
        Previous(e) {
            this.currentPage = e
            // this.getlist(localStorage.getItem('Language'))
        },
        //下一页
        NextPage(e) {
            this.currentPage = e
        },
        // 处理页码变化事件
        handleCurrentChange(page) {
            this.currentPage = page;
            this.getProductlist()
            // this.getlist(localStorage.getItem('Language'))
        },
    },
    watch: {

    },
    computed: {},
    filters: {},
    components: {}
}
</script>
<style>
/* 移动端分类css */
.el-select-dropdown__item.selected {
    color: #BA0003;
}

.el-select .el-input.is-focus .el-input__inner {
    width: 70vw !important;
    border-color: #BA0003;
    background: #BA0003;
    color: #fff;
}

.el-select:hover .el-input__inner,
.el-input__inner {
    width: 70vw !important;
    border-color: #BA0003;
    background: #BA0003;
    color: #fff;
}

.el-select .el-input .el-select__caret {
    color: #fff;
}

select {
    width: 100%;
    background: red;
    width: 80%;
    padding: 100px 50px;
    border-radius: 10px;
    border: 0;
    box-sizing: border-box;
}
</style>
<style scoped lang="less">
// 手机样式
@media (max-width: 768px) {
    .details_box_left_pb {
        display: block !important;
    }
    .mianbao {
        font-size: 0.4em !important;
    }

    .name {
        margin: 200px 0 50px 0 !important;

        h1 {
            font-size: 110px !important;
        }

        div {
            font-size: 70px !important;
            margin-bottom: 50px;
        }
    }

    .Product_list_left {
        display: none !important;
    }

    .Product_list_right {
        width: 100% !important;

        .list {
            display: grid;
            grid-template-columns: repeat(1, 1fr) !important;
            gap: 100px !important;
        }

        .Product_list_box {
            img {
                width: 100% !important;
                min-height: 1000px !important;
                object-fit: contain !important;
            }

            div {
                text-align: center !important;
                font-size: 80px !important;
                margin: 1em 0.5em !important;
                text-align: justify;
                word-wrap: break-word;
                overflow: hidden;
                text-overflow: ellipsis;
                display: -webkit-box;
                -webkit-box-orient: vertical;
                -webkit-line-clamp: 1;
            }
        }

        .yema {
            position: relative !important;
            text-align: center;
            margin-top: 50px;
        }
    }
}

// 平板样式
@media (min-width: 768px) and (max-width: 1024px) {
    .details_box_left_pb {
        display: block !important;
    }

    .yema {
        position: relative;
        text-align: center;
        margin-top: 50px;
    }

    .mianbao {
        font-size: 0.5em !important;
    }

    .name {
        h1 {
            font-size: 50px !important;
            margin: 40px 0 10px 0 !important;
        }

        div {
            font-size: 30px !important;
            margin-bottom: 50px;
        }
    }

    .Product_list_left {
        display: none !important;
    }

    .Product_list_right {
        width: 100% !important;

        .list {
            display: grid;
            grid-template-columns: repeat(2, 1fr) !important;
            gap: 1em !important;
        }

        .Product_list_box {
            img {
                min-height: 700px !important;
                object-fit: contain !important;
            }

            div {
                text-align: center !important;
                font-size: 40px !important;
                margin: 1em 0.5em !important;
                -webkit-line-clamp: 1;
                -webkit-box-orient: vertical;
                overflow: hidden;
                text-overflow: ellipsis;
                font-size: 19px;
                font-weight: bold;
            }
        }
    }
}

.screenWidth {
    padding-bottom: 100px;
}

.details_box_left_pb {
    display: none;
}

// pc样式
.activebk {
    color: #BA0003;
}

h1 {
    padding: 0;
    margin: 0;
}

.box {
    margin-top: 80px;
    padding: 90px 360px;
}

.mianbao {
    text-align: left;
    color: #545454;
    font-size: 18px;
}

.name {
    margin-top: 30px;
    cursor: default;

    h1 {
        font-size: 25px;
        margin-bottom: 5px;
    }

    div {
        font-size: 14px;
    }
}

.Product_list {
    margin-top: 25px;
    display: flex;
    justify-content: space-between;
}

.Product_list_left {
    width: 25%;

    .Product_list_left_one {
        background: #BA0003;
        color: #fff;
        padding: 5px 25px;
        line-height: 64px;
        text-align: left;
        font-size: 28px;
        cursor: default;
        word-break: break-word !important;

    }

    .Product_list_left_one_list {
        border: 1px solid #dcdcdc;

        ul li:hover {
            .text {
                color: #BA0003;
            }

            .fha span {
                background: #BA0003;
            }
        }

        ul {
            margin: 0;
            padding: 10px 20px 10px 5px;
            cursor: pointer;



            li:last-child {
                margin-bottom: 0 !important;
            }

            li {
                margin-bottom: 18px;
                display: flex;
                align-items: center;

                .text {
                    display: -webkit-box;
                    -webkit-line-clamp: 1;
                    -webkit-box-orient: vertical;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    font-size: 15px;
                    font-weight: bold;
                }

                .fha {
                    width: 5%;
                    height: 100%;
                    display: flex;
                    align-items: center;

                    span {
                        display: block;
                        width: 5px;
                        height: 5px;
                        background: #222;
                        border-radius: 50%;
                    }
                }
            }
        }
    }
}

.Product_list_right {
    width: 73%;
    min-height: 50vh;
    position: relative;

    .list {
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        gap: 40px;

        .Product_list_box:hover {
            box-shadow: 0px 0px 10px 1px rgba(0, 0, 0, 0.2);
        }

        .Product_list_box {
            width: 100%;
            border: 1px solid #dcdcdc;
            cursor: pointer;

            img {
                width: 100%;
                height: 270px;
            }

            div {
                text-align: center;
                font-size: 16px;
                font-weight: bold;
                margin: 30px 10px 30px 10px;
                word-break: break-word;
            }
        }
    }
}
</style>